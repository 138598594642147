import React, { Component } from "react";
// import { Link } from "gatsby";
// import blogImage from "../images/img15.jpg";
// import authorImage from "../images/author-avatar.jpg";
import JciMemberDonate from "./donate/JciMemberDonate";
import NonMemberDonate from "./donate/NonMemberDonate";
import OrganizationDonate from "./donate/OrganizationDonate";
import UpgradeTitleDonate from "./donate/UpgradeTitleDonate";


class Donate extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      ContributionTypeId: ''
    };
  }

  handleModal = (event, type) => {
    console.log('handleModal type=' + type);
    // console.log('handleModal called');
    // this.setState({ isOpen: !this.state.isOpen });
    this.setState({ ContributionTypeId: type });
  };
  render() {
    return (
      <React.Fragment>
        <section className="donate-area2">
          <div className="container">
            <div className="row donate-content-wrap">
              <div className="col-lg-12">
                <h3 className="donate__title">The Foundation kit will be printed in donor’s name and donor’s information are required to be filled in the below form</h3>
                <hr />
                <br />
                <h2 className="section__title"> Select your category below </h2>
              </div>
            </div>
            <br /><br />
            <div className="row donate-content-wrap">
              <div className="col-lg-3">
                <button onClick={(event) => this.handleModal(event, 1)} className="theme-btn submit__btn">
                  JCI India Member
                </button>
                <br /><br />
              </div>
              <div className="col-lg-3">
                <button onClick={(event) => this.handleModal(event, 2)} className="theme-btn submit__btn">
                  Non Member
                </button>
                <br /><br />
              </div>
              <div className="col-lg-3">
                <button onClick={(event) => this.handleModal(event, 3)} className="theme-btn submit__btn">
                  Organization
                </button>
                <br /><br />
              </div>
              <div className="col-lg-3">
                <button onClick={(event) => this.handleModal(event, 4)} className="theme-btn submit__btn">
                  Upgrade Title
                </button>
                <br /><br />
              </div>
              <div className="col-lg-12">
                {Number(this.state.ContributionTypeId) === 1 ? <JciMemberDonate /> : ''}
              </div>
              <div className="col-lg-12">
                {Number(this.state.ContributionTypeId) === 2 ? <NonMemberDonate /> : ''}
              </div>
              <div className="col-lg-12">
                {Number(this.state.ContributionTypeId) === 3 ? <OrganizationDonate /> : ''}
              </div>
              <div className="col-lg-12">
                {Number(this.state.ContributionTypeId) === 4 ? <UpgradeTitleDonate /> : ''}
              </div>
            </div>
          </div>
        </section>
        
        
      </React.Fragment>
    );
  }
}



export default Donate;
